import React from 'react';
import {Player} from 'video-react';

export default class DesktopVideo extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col-lg-12">
          <Player
            src={this.props.video.url}
          />
        </div>
      </div>
    );
  }
};
