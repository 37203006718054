import React from 'react';
import { NavLink } from 'react-router-dom';

import logo from '../../images/logo.svg';
import menu from '../../images/menu.svg';

import i18n from 'i18next';
import {
  useTranslation
} from 'react-i18next';

import { ThemeContext } from '../theme-context/themeContext';
import CustomLink from '../custom-link/CustomLink';


const Header = () => {

  const { toggle, toggleFunction } = React.useContext(ThemeContext);

  const { t } = useTranslation();

  const handleOnclick=(e)=>{
    e.preventDefault();
    i18n.changeLanguage(e.target.value);
    localStorage.setItem("language", e.target.value);
  }

  return (
    <header>
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="row">
            <div className="col-lg-5 col-6">
              <div className="logo">
                <CustomLink href="/" className="nav-link">
                  <img src={logo} alt="logo"/>
                </CustomLink>
              </div>
            </div>
            <div className="col-lg-2 col-6">
              <div className="layoutToggler only-desktop">
                <button
                  onClick={() => toggleFunction(false)}
                  className={`${!toggle ? "active" : ""}`}
                >
                  <span></span>
                </button>
                <button
                  onClick={() => toggleFunction(true)}
                  className={`${toggle ? "active" : ""}`}
                >
                  <span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                </button>
              </div>
            </div>
            <div className="col-lg-5 col-6 menu">
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <p>{t("menu")} <img src={menu} alt="menu" /></p>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <CustomLink href="/" className="nav-link">
                      {t("works")}
                    </CustomLink>
                  </li>
                  <li className="nav-item">
                    <CustomLink href="/services" className="nav-link">
                      {t("services")}
                    </CustomLink>
                  </li>
                  <li className="nav-item">
                    <CustomLink href="/about" className="nav-link">
                      {t("about")}
                    </CustomLink>
                  </li>
                  <li className="nav-item">
                    <CustomLink href="/contact" className="nav-link">
                      {t("contact")}
                    </CustomLink>
                  </li>
                  <li className="nav-item">
                    <button value='en' className={i18n.language === "en" ? "active" : ""} onClick={handleOnclick}>
                      EN
                    </button>
                    <button value='hu' className={i18n.language === "hu" ? "active" : ""} onClick={handleOnclick}>
                      HU
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
