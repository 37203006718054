import React from 'react';
import '../App.scss';

import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import Hero from '../components/hero/Hero';

function App() {
  return (
    <>
      <Header />
      <main>
        <Hero />
      </main>
      <Footer />
    </>
  );
}

export default App;
