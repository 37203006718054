import React, {useEffect, useState} from 'react';
import { Link as RouterLink } from 'react-router-dom';

import logo from '../../images/logo.svg';

import { ThemeContext } from '../theme-context/themeContext';
import LayoutToggler from '../layout-toggler/LayoutToggler';
import axios from "axios";

const Footer = () => {
  const [contactData, setContactData] = useState({"social": []});

  useEffect(() => {
    const getContact = () => {
      axios.get(process.env.REACT_APP_API_URL + '/' + localStorage.getItem("language") + `/contact`, {
        withCredentials: false,
      }).then(res => {
        setContactData(res.data);
      })
    }
    getContact();
  }, []);

  const { toggleFunction } = React.useContext(ThemeContext);

  return (
    <>
      <div className="only-mobile">
        <LayoutToggler />
      </div>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-12">
              <RouterLink to="/" class="nav-link p-0">
                <img src={logo} alt="logo"/>
              </RouterLink>
            </div>
            <div className="col-lg-4 col-12">
              <p>copyright©2022</p>
            </div>
            <div className="col-lg-4 col-12">
              {contactData.social.map((social, i) => (
                  <a href={social.url} target="_blank">{social.name}{contactData.social.length - 1 > i ? ', ' : ''}</a>
              ))}
              <a href={'mailto:' + contactData.email} target="_blank">{contactData.email}</a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
