import React from 'react';
import {Player} from "video-react";

export default class MobileVideo extends React.Component {
    state = {
        video: ''
    }
    render() {
        return (
            <div className="row">
                <div className="col-lg-12">
                    <Player
                        src={this.props.video}
                    />
                </div>
            </div>
        );
    }
};
