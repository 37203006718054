import React from 'react';

export default class Desktop1Big2SmallHorizontal extends React.Component {

  state = {
    big: '',
    small1: '',
    small2: '',
  }

  render() {
    return (
      <div className="row mb-3 pb-3">
        <div className="col-lg-12 mb-3 pb-3">
          <img src={this.props.big} alt="project"/>
        </div>
        <div className="col-lg-6">
          <img src={this.props.small1} alt="project"/>
        </div>
        <div className="col-lg-6">
          <img src={this.props.small2} alt="project"/>
        </div>
      </div>
    );
  }
};
