import React from 'react';

export default class Desktop1Big2SmallVertical extends React.Component {

  state = {
    big: '',
    small1: '',
    small2: '',
  }

  render() {
    return (
      <div className="row mb-3 pb-3">
        <div className="col-lg-8">
          <img src={this.props.big} alt="project"/>
        </div>
        <div className="col-lg-4">
          <div className="mb-3 pb-2">
            <img src={this.props.small1} alt="project"/>
          </div>
          <div>
            <img src={this.props.small2} alt="project"/>
          </div>
        </div>
      </div>
    );
  }
};
