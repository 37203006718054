import React from 'react';

export default class Desktop1Big3SmallHorizontal extends React.Component {
    state = {
        big: '',
        small1: '',
        small2: '',
        small3: '',
        reverse: false
    }
  render() {
        if(this.props.reverse) {
            return (
                <div className="row mb-4 pb-3">
                    <div className="col-lg-4 mb-4 pb-3 mb-lg-0">
                        <img src={this.props.small1} alt="project"/>
                    </div>
                    <div className="col-lg-4 mb-4 pb-3 mb-lg-0">
                        <img src={this.props.small2} alt="project"/>
                    </div>
                    <div className="col-lg-4">
                        <img src={this.props.small3} alt="project"/>
                    </div>
                    <div className="col-lg-12 mt-3">
                        <img src={this.props.big} alt="project"/>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="row mb-4 pb-3">
                    <div className="col-lg-12 pb-3 mb-4 mt-3">
                        <img src={this.props.big} alt="project"/>
                    </div>
                    <div className="col-lg-4 mb-4 pb-3 mb-lg-0">
                        <img src={this.props.small1} alt="project"/>
                    </div>
                    <div className="col-lg-4 mb-4 pb-3 mb-lg-0">
                        <img src={this.props.small2} alt="project"/>
                    </div>
                    <div className="col-lg-4">
                        <img src={this.props.small3} alt="project"/>
                    </div>
                </div>
            );
        }

  }
};
