import React from 'react';

export default class Desktop1Big2SmallVertical extends React.Component {

  state = {
    big: '',
    small1: '',
    small2: '',
    reverse: false
  }

  render() {
    if(this.props.reverse) {
      return (
          <div className="desktoponebig row mb-4 pb-3">
            <div className="col-lg-4">
              <div className="mb-4 pb-3">
                <img src={this.props.small1} alt="project"/>
              </div>
              <div>
                <img src={this.props.small2} alt="project"/>
              </div>
            </div>
            <div className="col-lg-8 mb-4 mb-lg-0">
              <img
                src={this.props.big}
                alt="project"
                width="101%"
                height="100.1%"
              />
            </div>
          </div>
      );
    } else {
      return (
          <div className="desktoponebig row mb-4 pb-3">
            <div className="col-lg-8 mb-4 mb-lg-0">
              <img
                src={this.props.big}
                alt="project"
                width="101%"
                height="100.1%"
              />
            </div>
            <div className="col-lg-4">
              <div className="mb-4 pb-3">
                <img src={this.props.small1} alt="project"/>
              </div>
              <div>
                <img src={this.props.small2} alt="project"/>
              </div>
            </div>
          </div>
      );
    }

  }
};
