import React from 'react';

export default class DesktopLeft1SmallRightBig extends React.Component {

  state = {
    big: '',
    small: '',
    reverse: false
  }

  render() {
      if(this.props.reverse) {
          return (
              <div className="row mb-4 pb-3 reverse d-flex align-items-end">
                  <div className="col-lg-4 mb-4 mb-lg-0">
                      <img src={this.props.small} alt="project"/>
                  </div>
                  <div className="col-lg-8">
                      <img src={this.props.big} alt="project"/>
                  </div>
              </div>
          );
      } else {
          return (
              <div className="row mb-4 pb-3">
                  <div className="col-lg-4 mb-4 pb-2 mb-lg-0">
                      <img src={this.props.small} alt="project"/>
                  </div>
                  <div className="col-lg-8">
                      <img src={this.props.big} alt="project"/>
                  </div>
              </div>
          );
      }

  }
};
