import React, { useState, useEffect } from 'react';
import {
  useTranslation
} from 'react-i18next';

import Header from '../../components/header/Header';
import axios from "axios";
import i18n from "i18next";

const Contact = () => {
  const [contactData, setContactData] = useState({'social': []});

  useEffect(() => {
    const getContacts = () => {
      axios.get(process.env.REACT_APP_API_URL + '/' + i18n.language + `/contact`, {
        withCredentials: false,
      }).then(res => {
        setContactData(res.data);
      })
    }

    i18n.on('languageChanged', function(lng) {
      getContacts();
    })

    getContacts();
  }, []);

  const { t } = useTranslation();

  return (
      <main className="contact">
        <Header/>
        <section className="contact__inner py-5 my-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 mb-5 mb-lg-0">
                <h1>{t("email")}</h1>
                <h3>
                  <a href="mailto:hello@solvae.com">
                    {contactData.email}
                  </a>
                </h3>
                <p>
                  {contactData.social.map((social, i) => (
                    <a href={social.url} target="_blank">{social.name}{contactData.social.length - 1 > i ? ', ' : ''}</a>
                    ))}
                </p>
              </div>
              <div className="col-lg-4 mb-5 mb-lg-0 phone">
                <h1>{t("mobil")}</h1>
                <h3>
                <a href="tel:36306383378">
                    {contactData.phone}
                  </a>
                </h3>
              </div>
              <div className="col-lg-4 mb-5 mb-lg-0">
                <h1>{t("address")}</h1>
                <h3 dangerouslySetInnerHTML={{ __html: contactData.address }}>
                </h3>
                <p>
                  <a href={contactData.map} target="_blank">
                    {t("map")}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <p>copyright©2022</p>
              </div>
            </div>
          </div>
        </footer>
      </main>
  )
}

export default Contact;
