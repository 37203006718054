import React from 'react';

export default class MobileSmall extends React.Component {

  state = {
    image: ''
  }

  render() {
    return (
      <div className="row mb-3 pb-3">
        <div className="col-lg-12 mb-3 pb-3 pl-2 pr-2">
          <img src={this.props.image} alt="project"/>
        </div>
      </div>
    );
  }
};
