import React from 'react';

const ThemeContext = React.createContext(false);
const ThemeProvider = ({ children })  =>  {
    const [toggle, setToggle] = React.useState(false);
    const toggleFunction = (isToggle = false) => {
    setToggle(isToggle);
};

return  (
    <ThemeContext.Provider value={{ toggle, toggleFunction }}>
        {children}
    </ThemeContext.Provider>
    );
};

export  { ThemeContext, ThemeProvider };
