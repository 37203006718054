import React from 'react';

import { ThemeContext } from '../theme-context/themeContext';

const LayoutToggler = () => {

  const { toggle, toggleFunction } = React.useContext(ThemeContext);

  return (
    <div className="changeLayout">
      <div className="row">
        <div className="col-12">
          <div className="layoutToggler">
            <button
              onClick={() => toggleFunction(false)}
              className={`${!toggle ? "active" : ""}`}
            >
              <span></span>
            </button>
            <button
              onClick={() => toggleFunction(true)}
              className={`${toggle ? "active" : ""}`}
            >
              <span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LayoutToggler;
