import React from 'react';

export default class DesktopLeft1SmallRightBig extends React.Component {

  state = {
    big: '',
    small: '',
  }

  render() {
    return (
      <div className="row mb-3 pb-3">
        <div className="col-lg-4">
          <img src={this.props.small} alt="project"/>
        </div>
        <div className="col-lg-8">
          <img src={this.props.big} alt="project"/>
        </div>
      </div>
    );
  }
};
