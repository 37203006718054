import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import axios from 'axios';

import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import {useTranslation} from "react-i18next";
import i18n from "i18next";

import menu from '../../images/menu.svg';

const SETTINGS = {
  dots: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  infinite: false,
  transformEnabled: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        infinite: true,
      }
    }
  ]
};

const About = () => {
  const { t } = useTranslation();

  const [teamData, setTeamData] = useState({'teammates': [], 'recognitions': [], 'exhibitions': [], 'partners': []});

  useEffect(() => {
    const getTeam = () => {
      axios.get(process.env.REACT_APP_API_URL + '/' + i18n.language + `/team`, {
        withCredentials: false,
      }).then(res => {
        setTeamData(res.data);
      })
    }

    i18n.on('languageChanged', function(lng) {
      getTeam();
    })

    getTeam();
  }, []);

  return (
    <main className="about">
      <Header />
      <section className="py-5 my-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 only-mobile">
              <h1>
                <a data-toggle="collapse" href="#item" role="button" aria-expanded="false" aria-controls="collapseExample">
                  Manifesto
                  <img src={menu} alt="menu" />
                </a>
              </h1>
              <div className="collapse" id="item">
                <div
                  className="row intro only-mobile"
                  dangerouslySetInnerHTML={{ __html: teamData.description }}
                >
                </div>
              </div>
            </div>
            <div className="col-lg-12 title only-desktop">
              <h1>Manifesto</h1>
            </div>
          </div>
          <div
            className="row intro only-desktop"
            dangerouslySetInnerHTML={{ __html: teamData.description }}
          >
          </div>
          <div className="row members">
            {teamData.teammates.map(teammate => (
              <div className="col-lg-4 pb-3">
                <img src={teammate.image.url} alt="member"/>
                <h3>{teammate.name}</h3>
                <p>{teammate.details}</p>
              </div>
            ))}
          </div>
          <div className="row partners">
            <div className="col-12 title">
              <h1>{t("partners")}</h1>
            </div>
            <Slider {...SETTINGS} className="slider">
              {teamData.partners.map(partner => (
                <div className="col">
                  <img src={partner.image} alt="partner"/>
                </div>
              ))}
            </Slider>
          </div>
          <div className="row list">
            <div className="col-lg-6">
              <h5>{t("recognitions")}</h5>
              <div className="list__inner">
                <ul>
                  {teamData.recognitions.map(recognitions => (
                    <li>
                      <p><strong>{recognitions.year}</strong></p>
                      {recognitions.items.map(recognition => (
                        <p>{recognition}</p>
                      ))}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <h5>{t("exhibitions")}</h5>
              <div className="list__inner">
                <ul>
                {teamData.exhibitions.map(exhibitions => (
                  <li>
                    <p><strong>{exhibitions.year}</strong></p>
                    {exhibitions.items.map(exhibition => (
                      <p>{exhibition}</p>
                    ))}
                  </li>
                ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
}

export default About;
