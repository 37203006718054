import React from 'react';
import ReactPlayer from "react-player";

export default class MobileVideo extends React.Component {
    state = {
        video: ''
    }
    render() {
        return (
            <div className="row">
                <div className="col-lg-12">
                    <ReactPlayer
                        controls
                        url={this.props.video}
                        config={{
                            youtube: {
                                playerVars: {showinfo: 0}
                            },
                            file: {
                                controls: true
                            }
                        }}
                    />
                </div>
            </div>
        );
    }
};
