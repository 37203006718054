import React from 'react';

import i18n from 'i18next';
import {
  initReactI18next
} from 'react-i18next';

import {
  BrowserRouter,
  Route, Routes
} from 'react-router-dom';

import ScrollToTop from './components/scrolltotop/ScrollToTop';

import About from './pages/about/About';
import Services from './pages/services/Services';
import Contact from './pages/contact/Contact';
import Project from './pages/project/Project';
import IndexPage from './pages/IndexPage';
import ProjectPreview from "./pages/projectpreview/ProjectPreview";

if (!localStorage.getItem("language")) {
    localStorage.setItem("language", "hu");
}

i18n
  .use(initReactI18next)
  .init({
    resources: {
      hu: {
        translation: {
          menu: "menü",
          works: "munkáink",
          services: "szolgáltatásaink",
          about: "rólunk",
          contact: "kapcsolat",
          map: "Térkép",
          partners: "Partnereink",
          recognitions: "Elismeréseink",
          exhibitions: "Kiállításaink",
          email: "Email",
          mobil: "Mobil",
          address: "Cím",
          installation: "Installáció",
          graphicdesign: "Grafika Design",
          execution: "Kivitelezés",
          festival: "Fesztivál",
          events: "Rendezvény",
          exhibitions: "Kiállítás",
          furnituredesign: "Bútor tervezés",
          productdesign: "Termék tervezés",
          campaignactivation: "Kampány aktiváció",
          collaboration: "Együttműködés",
          branding: "Arculat tervezés",
          editorialdesign: "Kiadvány tervezés",
          packagingdesign: "Csomagolás tervezés",
          servicedesign: "Service design",
          uxuidesign: "UX/UI design",
          illustration: "Illusztráció",
          textiledesign: "Textil design",
          motiondesign: "Reklámfilm",
          installationproduction: "Installáció építés",
          prototypeproduction: "Prototípus gyártás",
          seriesproduction: "Széria gyártás",
          textileprinting: "Textilnyomás",
          graphicsanddtp: "Grafika és DTP",
          filmproduction: "Videó gyártás"
        }
      },
      en: {
        translation: {
          menu: "menu",
          works: "works",
          services: "services",
          about: "team",
          contact: "contact",
          map: "Map",
          partners: "Collaborators",
          recognitions: "Recognitions",
          exhibitions: "Exhibitions",
          email: "Email",
          mobil: "Phone",
          address: "Address",
          installation: "Installation",
          graphicdesign: "Graphic Design",
          execution: "Execution",
          festival: "Festival",
          events: "Events",
          exhibitions: "Exhibitions",
          furnituredesign: "Furniture design",
          productdesign: "Product design",
          campaignactivation: "Campaign activation",
          collaboration: "Collaboration",
          branding: "Branding",
          editorialdesign: "Editorial design",
          packagingdesign: "Packaging design",
          servicedesign: "Service design",
          uxuidesign: "UX/UI design",
          illustration: "Illustration",
          textiledesign: "Textile design",
          motiondesign: "Motion design",
          installationproduction: "Installation production",
          prototypeproduction: "Prototype production",
          seriesproduction: "Series production",
          textileprinting: "Textile printing",
          graphicsanddtp: "Graphics and DTP",
          filmproduction: "Film production"
        }
      }
    },
    lng: localStorage.getItem("language"),
    fallbackLng: "hu",

    interpolation: {
      escapeValue: false
    }
  });

const App = () => {
  return (
    <BrowserRouter forceRefresh>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={
          <IndexPage />
        } />
        <Route exact path="about" element={
          <About />
        } />
        <Route exact path="services" element={
          <Services />
        } />
        <Route exact path="contact" element={
          <Contact />
        } />
        <Route exact path="project/:slug" element={
            <Project />
        } />
        <Route exact path="preview/:id" element={
            <ProjectPreview />
        } />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
