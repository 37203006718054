import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';

// Layouts
import Desktop1Big2SmallHorizontal from './Layouts/Desktop1Big2SmallHorizontal.js';
import Desktop1Big2SmallVertical from './Layouts/Desktop1Big2SmallVertical.js';
import Desktop1Big3SmallHorizontal from './Layouts/Desktop1Big3SmallHorizontal.js';
import DesktopLeft1SmallRightBig from './Layouts/DesktopLeft1SmallRightBig.js';
import DesktopLeftBigRight1Small from './Layouts/DesktopLeftBigRight1Small.js';
import DesktopVideo from './Layouts/DesktopVideo.js';
import DesktopVideoUrl from './Layouts/DesktopVideoUrl.js';
import MediaQuery from "react-responsive";
import MobileBig from "../project/Layouts/MobileBig";
import MobileSmall from "../project/Layouts/MobileSmall";
import MobileVideoUrl from "../project/Layouts/MobileVideoUrl";
import {useParams} from "react-router-dom";
import i18n from "i18next";
import MobileVideo from "../project/Layouts/MobileVideo";
import menu from "../../images/menu.svg";

const ProjectPreview = () => {

  const [projectData, setProjectData] = useState({"project_platforms":{"desktop": [], "mobile": []}, "participants": []});
  let { id } = useParams();

  useEffect(() => {
    const getProject = () => {
      axios.get(process.env.REACT_APP_API_URL + '/' + i18n.language + `/project-preview/` + id, {
        withCredentials: false,
      }).then(res => {
        setProjectData(res.data);
        this.forceUpdate();
      })
    }

    i18n.on('languageChanged', function(lng) {
        getProject();
    })

    getProject();
  }, []);

  const renderProject = () => {
    return (
        <main className="project">
          <Header />
          <section className="project__inner">
            <div className="container">
              <div className="row mb-3 pb-3">
                <div className="col-lg-3 only-mobile">
                  <h1>
                    <a data-toggle="collapse" href="#item" role="button" aria-expanded="false" aria-controls="collapseExample">
                      {projectData.title}
                      <img src={menu} alt="menu" />
                    </a>
                  </h1>
                  <div className="collapse" id="item">
                    <p>
                      {projectData.description}
                    </p>
                  </div>
                </div>
                <div className="col-xl-8">
                  <div className="project__inner--img">
                    <img className="content-image" src={projectData.cover_desktop_url} alt="asdf" />
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="only-desktop">
                    <h1>{projectData.title}</h1>
                    <p>
                      {projectData.description}
                    </p>
                  </div>
                </div>
              </div>

              <MediaQuery minDeviceWidth={1224}>
                {projectData.project_platforms.desktop.map(projectPlatform => (
                    (() => {
                      switch (projectPlatform.type) {
                        case "Desktop1Big2SmallHorizontal":
                          return (
                              <Desktop1Big2SmallHorizontal big={projectPlatform.blocks.big.url} small1={projectPlatform.blocks.small1.url} small2={projectPlatform.blocks.small2.url} />
                          )
                        case "Desktop1Big2SmallVertical":
                          return (
                              <Desktop1Big2SmallVertical big={projectPlatform.blocks.big.url} small1={projectPlatform.blocks.small1.url} small2={projectPlatform.blocks.small2.url} />
                          )
                        case "Desktop1Big3SmallHorizontal":
                          return (
                              <Desktop1Big3SmallHorizontal big={projectPlatform.blocks.big.url} small1={projectPlatform.blocks.small1.url} small2={projectPlatform.blocks.small2.url} small3={projectPlatform.blocks.small3.url} />
                          )
                        case "DesktopLeft1SmallRightBig":
                          return (
                              <DesktopLeft1SmallRightBig big={projectPlatform.blocks.big.url} small={projectPlatform.blocks.small.url} />
                          )
                        case "DesktopLeftBigRight1Small":
                          return (
                              <DesktopLeftBigRight1Small big={projectPlatform.blocks.big.url} small={projectPlatform.blocks.small.url} />
                          )
                        case "DesktopVideo":
                          return (
                              <DesktopVideo video={projectPlatform.blocks.video} />
                          )
                        case "DesktopVideoUrl":
                          return (
                              <DesktopVideoUrl video={projectPlatform.blocks.video_url} />
                          )
                        default:
                          return ('')
                      }
                    })()
                ))}
              </MediaQuery>
              <MediaQuery minDeviceWidth={1} maxDeviceWidth={1223}>
                {projectData.project_platforms.mobile.map(projectPlatform => (
                    (() => {
                      switch (projectPlatform.type) {
                        case "MobileBig":
                          return (
                              <MobileBig image={projectPlatform.blocks.image.url} />
                          )
                        case "MobileSmall":
                          return (
                              <MobileSmall image={projectPlatform.blocks.image.url} />
                          )
                        case "MobileVideo":
                          return (
                              <MobileVideo video={projectPlatform.blocks.video} />
                          )
                        case "MobileVideoUrl":
                          return (
                              <MobileVideoUrl video={projectPlatform.blocks.video_url} />
                          )
                        default:
                          return ('')
                      }
                    })()
                ))}
              </MediaQuery>

              <div className="row project__inner--footer">
                {projectData.participants.map(participant => (
                    <div className="col-lg-4">
                      <p>{participant.label}:</p>
                      <p>{participant.details}</p>
                    </div>
                ))}
              </div>
            </div>
          </section>
          <Footer />
        </main>
    );
  };

  return (
      renderProject()
  )
}

export default ProjectPreview;
