import React from 'react';

import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';

import plus from '../../images/plus.svg';
import and from '../../images/and.svg';

import i18n from 'i18next';
import {
  useTranslation
} from 'react-i18next';

const Services = () => {

  const { t } = useTranslation();

  return (
    <main className="services">
      <Header />
      <section className="py-5 my-5">
        <div className="container">
          <div className="row only-mobile mb-5">
            <h2>
              {t("installation")} <span className="and">
                <img
                  src={and}
                  height={38}
                  style={{ marginTop: '-10px', display: 'inline-block' }}
                  className="only-mobile"
                />
              </span>
              <br />
              {t("graphicdesign")}
              <br />
              <span className="plus">
                <img
                  src={plus}
                  height={38}
                  style={{ marginTop: '-10px', display: 'inline-block' }}
                  className="only-mobile"
                />
              </span> {t("execution")}
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-8 col-6 servicelist">
              <ul>
                <li className="active">
                  <a href="#tab-1" data-toggle="tab">
                    {t("installation")} <span>
                      <img
                        src={and}
                        height={80}
                        style={{
                          marginBottom: '-15px',
                          marginLeft: '-15px'
                        }}
                        className="only-desktop"
                      />
                    </span>
                  </a>
                </li>
                <li>
                  <a href="#tab-2" data-toggle="tab">
                    {t("graphicdesign")}
                  </a>
                </li>
                <li>
                  <a href="#tab-3" data-toggle="tab">
                    <span style={{ width: '60px' }}>
                      <img
                        src={plus}
                        height={100}
                        style={{
                          marginBottom: '-25px',
                          marginLeft: '-20px'
                        }}
                        className="only-desktop"
                      />
                    </span> {t("execution")}
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-6 serviceitems">
              <div className="tab-content well">
                <div className="tab-pane active" id="tab-1">
                  <div>
                    <p>{t("festival")}</p>
                    <p>{t("events")}</p>
                    <p>{t("exhibitions")}</p>
                    <p>{t("furnituredesign")}</p>
                    <p>{t("productdesign")}</p>
                    <p>{t("campaignactivation")}</p>
                    <p>{t("collaboration")}</p>
                  </div>
                </div>
                <div className="tab-pane" id="tab-2">
                  <div>
                    <p>{t("branding")}</p>
                    <p>{t("editorialdesign")}</p>
                    <p>{t("packagingdesign")}</p>
                    <p>{t("servicedesign")}</p>
                    <p>{t("uxuidesign")}</p>
                    <p>{t("illustration")}</p>
                    <p>{t("textiledesign")}</p>
                    <p>{t("motiondesign")}</p>
                  </div>
                </div>
                <div className="tab-pane" id="tab-3">
                  <div>
                    <p>{t("installationproduction")}</p>
                    <p>{t("prototypeproduction")}</p>
                    <p>{t("seriesproduction")}</p>
                    <p>{t("textileprinting")}</p>
                    <p>{t("graphicsanddtp")}</p>
                    <p>{t("filmproduction")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
}

export default Services;
