import { useLocation } from "react-router-dom";

export default function CustomLink (props) {
  const location = useLocation();

  const isActive = location.pathname === props.href;

  console.log({ location, isActive });
  const handleClick = () => {
    window.location = props.href
  }

  return (
    <a onClick={handleClick} className={`nav-link ${isActive ? 'active' : ''}`}>
      {props.children}
    </a>
  );
}
