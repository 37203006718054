import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import MediaQuery from 'react-responsive'
import axios from 'axios';

import { ThemeContext } from '../theme-context/themeContext';
import { Player, ControlBar, BigPlayButton } from 'video-react';

import i18n from "i18next";

const Hero = () => {

  const [mainData, setMainData] = useState({'projects': [], 'video': {'url': ''}, 'video_cover': {'url': ''}});

  useEffect(() => {
    const getMain = () => {
      axios.get(process.env.REACT_APP_API_URL + '/' + i18n.language + `/main`, {
        withCredentials: false,
      }).then(res => {
        setMainData(res.data);
      })
    }

    i18n.on('languageChanged', function(lng) {
      getMain();
    })

    getMain();
  }, []);

  const { toggle } = React.useContext(ThemeContext);

  return (
    <section className="hero" id="hero">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2>{toggle ? '' : mainData.manifesto}</h2>
          </div>
        </div>
        <div className="row">
          <div className={toggle ? "d-none" : "col-lg-12"}>
            <div className="project video">
              <Player
                src={mainData.video.url}
                poster={mainData.video_cover.url}
                autoPlay
                muted
              >
                <BigPlayButton position="center" />
                <ControlBar autoHide={false} className="my-class" />
              </Player>
            </div>
          </div>
          {mainData.projects.map(project => (
            <div className={toggle ? "col-lg-4 col-6" : "col-lg-12"}>
              <div className="project">
                <div className="content">
                  <RouterLink to={"/project/" + project.slug}>
                    <div className="content-overlay"></div>
                    <MediaQuery minDeviceWidth={1224}>
                      <LazyLoadImage
                        className="content-image"
                        src={project.cover_desktop_url}
                        alt={project.slug}
                        effect="blur"
                      />
                    </MediaQuery>
                    <MediaQuery minDeviceWidth={1} maxDeviceWidth={1223}>
                      <LazyLoadImage
                        className="content-image"
                        src={project.cover_mobile_url}
                        alt={project.slug}
                        effect="blur"
                      />
                    </MediaQuery>
                    <div className="content-details">
                      <h3 className="content-title">{project.title}</h3>
                    </div>
                  </RouterLink>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Hero;
